import React from 'react'
import { css } from '@emotion/core'
import {
  colors,
  breakpoints,
  spacer,
} from '@dqp/common/styles/utilities'
import Title from '@dqp/common/components/Title/Title'
import Text from '@dqp/common/components/Text/Text'
import Image from '@dqp/common/components/Image'
import ResetPasswordForm from './ResetPasswordForm'

const styles = {
  contaner: css`
    background-color: ${colors.yellow};
    ${breakpoints({
      paddingTop: [40, 40, 100, 120, 120],
      paddingBottom: [80, 80, 100, 240, 240],
    })};
  `,
  title: css`
    ${spacer.mrB20};
  `,
  text: css`
    line-height: 1.5;
    ${spacer.mrB30};
  `,
  casette: css`
    ${breakpoints({
      top: [0, 0, 0, -265, -265],
      right: [0, 0, 0, -70, -70],
    })};
  `,
}
function Hero() {
  return (
    <section css={styles.contaner}>
      <div className='container relative'>
        <div className='row align-items-center'>
          <div className='col-lg-5'>
            <Image
              css={styles.casette}
              className='d-none d-lg-block'
              alt='casette '
              image='resetpassword/casette'
              sizes={{
                lg: {
                  width: 817,
                },
                md: {},
              }}
            />
          </div>
          <div className='offset-lg-1 col-lg-6'>
            <Title
              as='h1'
              size='xxxxLarge'
              weight='bold'
              color={colors.black}
              css={styles.title}
            >
              Reset password
            </Title>
            <Text size='large' color={colors.black} css={styles.text}>
              Don’t worry! We’ll email you instructions to reset your
              password.
            </Text>
            <ResetPasswordForm />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
