import React from 'react'
import { css } from '@emotion/core'
import { colors } from '@dqp/common/styles/utilities'
import Wave from '@dqp/common/components/Wave/Wave'
import Hero from './Hero'

const styles = {
  wave: css`
    transform: rotateY(180deg);
  `,
}
function ResetPassword() {
  return (
    <>
      <Hero />
      <Wave
        bg={colors.yellow}
        fill={colors.white}
        css={styles.wave}
        type='footer'
      />
    </>
  )
}

export default ResetPassword
