import React, { useState, useEffect } from 'react'
import { css } from '@emotion/core'
import { useFormik } from 'formik'
import { object, string } from 'yup'
import { colors, spacer } from '@dqp/common/styles/utilities'
import Button from '@dqp/common/components/Button/Button'
import Text, {
  ErrorText,
  SuccessText,
} from '@dqp/common/components/Text/Text'
import Link from '@dqp/common/components/Link/Link'
import TextField from '@dqp/common/components/TextField/TextField'
import { get } from 'lodash-es'
import routes from '#globals/routes'
import { useForgotPassword } from '#api/auth'
import { apiStatuses } from '#globals/constants'
import Stack from '#components/Stack/Stack'
import parseValidationErrors from '#utils/parseValidationErrors'
import getErrorMessage from '#utils/getErrorMessage'

const styles = {
  text: css`
    line-height: 1.5;
    ${spacer.mrT30};
  `,
}
const ResetPasswordSchema = object().shape({
  email: string()
    .email('Invalid email.')
    .required('Email is required.'),
})

function ResetPasswordForm() {
  const { forgotPassword } = useForgotPassword()
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    errors,
    isValid,
    touched,
    isSubmitting,
    resetForm,
  } = useFormik({
    validateOnBlur: true,
    validationSchema: ResetPasswordSchema,
    initialValues: {
      email: '',
    },

    onSubmit: async (formData, actions) => {
      try {
        const { data } = await forgotPassword({
          variables: {
            email: formData.email,
          },
        })
        const { status, message } = get(data, 'forgotPassword', {})

        if (status === apiStatuses.forgotPassword.EMAIL_NOT_SENT) {
          setErrorMessage(message)
        } else {
          resetForm()
          setSuccessMessage(message)
        }
      } catch (error) {
        const validationErrors = parseValidationErrors(error)
        const message = getErrorMessage(error)
        if (validationErrors) actions.setErrors(validationErrors)
        else if (message) setErrorMessage(message)
      }
    },
  })
  useEffect(() => {
    setErrorMessage('')
  }, [values])
  return (
    <form onSubmit={handleSubmit}>
      <fieldset disabled={!!successMessage}>
        <Stack spacing={20}>
          <div>
            <TextField
              type='text'
              name='email'
              label='Email'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              error={touched.email && errors.email}
            />
          </div>
          {errorMessage && <ErrorText>{errorMessage}</ErrorText>}

          {successMessage && (
            <SuccessText>{successMessage}</SuccessText>
          )}
          <div
            className='d-flex align-items-center justify-content-between'
            css={styles.text}
          >
            <Button
              size='medium'
              variant='black'
              disabled={!isValid || isSubmitting}
              isLoading={isSubmitting}
              type='submit'
            >
              RESET PASSWORD
            </Button>
            <Text
              as='strong'
              size='small'
              color={colors.black}
              className='d-sm-flex d-none'
              weight='bold'
            >
              <Link to={routes.login.to} borderColor={colors.black}>
                Return to login
              </Link>
            </Text>
          </div>
        </Stack>
      </fieldset>
    </form>
  )
}

export default ResetPasswordForm
